import React, { useContext, useEffect } from "react";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { UserActivity } from "../../../context/UserActivity";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
import { useScroll } from "../../../custom-hooks/useScroll";
import { NavbarActivity } from "../../../context/NavbarActivity";
import { useNavigate } from "react-router-dom";
//STYLES
import "./ContactPage.css";

//IMAGES
import logo from "../../../assets/Footer/contactFooter.svg";
import mapa from "../../../assets/ContactPage/mapaDesktop.jpg";
import mapaMobile from "../../../assets/ContactPage/mapaMobile.jpg";
//COMPONENTS
import Newsletter from "../../Individual/Newsletter/Newsletter";
import Footer from "../../Individual/Footer/Footer";
const ContactPage = () => {
  const { data, setLanguageSelected } = useContext(LanguageActivity);
  const displayMobile = useWindowSize();
  const { dropdownMenuSelected } = useContext(UserActivity);
  const { setNavbarColor, setHamburgerColor } = useContext(NavbarActivity);
  const navigate = useNavigate();
  const { scrollPercent } = useScroll();
  const handlelanguage = (key) => {
    if (key === 1) {
      setLanguageSelected({ catala: true, castella: false, angles: false });
      localStorage.setItem(
        "language",
        JSON.stringify({ catala: true, castella: false, angles: false })
      );
    }
    if (key === 0) {
      setLanguageSelected({ catala: false, castella: true, angles: false });
      localStorage.setItem(
        "language",
        JSON.stringify({ catala: false, castella: true, angles: false })
      );
    }
    if (key === 2) {
      setLanguageSelected({ catala: false, castella: false, angles: true });
      localStorage.setItem(
        "language",
        JSON.stringify({ catala: false, castella: false, angles: true })
      );
    }
  };

  useEffect(() => {
    if (displayMobile) {
      setHamburgerColor("var(--mercapink)");
      setNavbarColor("var(--mercapink)");
    } else if (!displayMobile) {
      setHamburgerColor("var(--mercared)");
      setNavbarColor("var(--mercapink)");
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!displayMobile) {
      if (scrollPercent >= 0 && scrollPercent < 28) {
        setHamburgerColor("var(--mercared)");
      } else if (scrollPercent > 28 && scrollPercent < 60) {
        setNavbarColor("var(--mercapink)");
        setHamburgerColor("var(--mercapink)");
      } else if (scrollPercent > 60 && scrollPercent < 90) {
        setNavbarColor("var(--mercared)");
        setHamburgerColor("var(--mercapink)");
      } else if (scrollPercent > 90) {
        setHamburgerColor("var(--mercared)");
        setNavbarColor("var(--mercared)");
      }
    }
    //eslint-disable-next-line
  }, [scrollPercent]);
  if (displayMobile) {
    return (
      <div className={dropdownMenuSelected ? "overflow" : "contactPage__pater"}>
        <div className="contactPage__container">
          <div className="contactPage__content-wrapper">
            {data.contacte !== undefined && (
              <div className="contactPage__content">
                <div>
                  <div className="contactPage__info-container">
                    {data.contacte.emailInfo.map((item, index) => {
                      return (
                        <p
                          key={item.key}
                          style={index === 2 ? { marginBottom: "0.25rem" } : {}}
                        >{`${item.text} ${item.email}`}</p>
                      );
                    })}
                  </div>
                  <div
                    className="contactPage__info-container"
                    style={{ paddingTop: "8px" }}
                  >
                    {data.contacte.basicInfo.map((item, index) => {
                      return (
                        <p className={index === 3 && "last-child"}>{item}</p>
                      );
                    })}
                  </div>
                </div>
                <div
                  style={{
                    height: "calc(45vh - 6.5rem)",
                  }}
                >
                  {data.contacte.espais.map((item, index) => {
                    return (
                      <>
                        <p
                          style={
                            index === 0
                              ? {
                                  marginBottom: "0.25rem",
                                  paddingTop: "0px",
                                  marginTop: "0.5rem",
                                }
                              : { marginBottom: "0.25rem", paddingTop: "8px" }
                          }
                        >
                          {item.space}
                        </p>
                        <p style={{ marginTop: 0 }}>{item.br1}</p>
                        {item.br2 !== undefined && <p>{item.br2}</p>}
                      </>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="contactPage__languages">
              {data.dropdown !== undefined &&
                data.dropdown.languages.map((item) => {
                  return (
                    <p
                      key={item.key}
                      style={{
                        color: "var(mercapink)",
                        margin: "0",
                        marginTop: "15px",
                        marginBottom: "15px",
                      }}
                      onClick={() => {
                        handlelanguage(item.key);
                      }}
                    >
                      {item.text}
                    </p>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="contactePage__footer-wrapper">
          <div className="contactPage__footer">
            {data.dropdown !== undefined && (
              <>
                <p
                  onClick={() => {
                    navigate("/event");
                  }}
                >
                  {data.dropdown.footer.footer1}
                </p>
                <p
                  onClick={() => {
                    navigate("/company");
                  }}
                >
                  {data.dropdown.footer.footer2}
                </p>
              </>
            )}
          </div>

          {data.contacte !== undefined && (
            <div
              style={{
                borderTop: "1px solid var(--mercared)",
                borderBottom: "1px solid var(--mercared)",
                paddingBottom: "20px",
                paddingTop: "20px",
                // display: "flex",
                // flexDirection: "column",
                // alignItems: "self-start",
              }}
            >
              <p
                style={{
                  color: "var(--mercared)",
                  fontSize: "14px",
                  marginBottom: "15px",
                  marginTop: "0",
                }}
              >
                {data.contacte.howToGet.first.first}
              </p>
              <p
                style={{
                  color: "var(--mercared)",
                  fontSize: "14px",
                  marginBottom: "15px",
                }}
              >
                {data.contacte.howToGet.first.second}
              </p>
              <p
                style={{
                  color: "var(--mercared)",
                  fontSize: "14px",
                  marginBottom: "15px",
                }}
              >
                {data.contacte.howToGet.first.third}
              </p>
              <p
                style={{
                  color: "var(--mercared)",
                  fontSize: "14px",
                  marginBottom: "0px",
                }}
              >
                {data.contacte.howToGet.first.fourth}
              </p>
            </div>
          )}
          {data.contacte !== undefined && (
            <div
              style={{
                paddingBottom: "20px",
                paddingTop: "20px",
              }}
            >
              <p
                style={{
                  color: "var(--mercared)",
                  fontSize: "14px",
                  marginBottom: "15px",
                  marginTop: "0",
                }}
              >
                {data.contacte.howToGet.second.first}
              </p>
              <p
                style={{
                  color: "var(--mercared)",
                  fontSize: "14px",
                  marginBottom: "15px",
                }}
              >
                {data.contacte.howToGet.second.second}
              </p>
              <p
                style={{
                  color: "var(--mercared)",
                  fontSize: "14px",
                  marginBottom: "50px",
                }}
              >
                {data.contacte.howToGet.second.third}
              </p>
            </div>
          )}
        </div>
        <img src={mapaMobile} alt="map" />
        <Footer
          backgroundColor={"var(--mercared)"}
          color={"var(--mercapink)"}
          logo={logo}
        />
      </div>
    );
  } else if (!displayMobile) {
    return (
      <div className={dropdownMenuSelected ? "overflow" : "contactPage__pater"}>
        <div className="contactPage__container">
          {data.contacte !== undefined && (
            <div className="contactPage__content">
              <div className="contactPage__content__left">
                <div className="contactPage__info-container contactPage-margin-info1-container">
                  {data.contacte.emailInfo.map((item) => {
                    return <p key={item.key}>{`${item.text} ${item.email}`}</p>;
                  })}
                </div>
                <div className="contactPage__info-container contactPage-margin-info1-container">
                  {data.contacte.basicInfo.map((item, index) => {
                    return (
                      <p className={index === 3 && "last-child"} key={index}>
                        {item}
                      </p>
                    );
                  })}
                </div>
                <div>
                  {data.contacte.espais.map((item, index) => {
                    return (
                      <div key={index}>
                        <p
                          style={{ marginTop: "1rem", marginBottom: "0.25rem" }}
                        >
                          {item.space}
                        </p>
                        <p style={{ marginTop: 0 }}>{item.br1}</p>
                        {item.br2 !== undefined && <p>{item.br2}</p>}
                      </div>
                    );
                  })}
                </div>
                {/* <div
                  style={{
                    position: "absolute",
                    bottom: "0",
                    width: "calc(100% - 40px)",
                    borderTop: "1px solid var(--mercapink)",
                    paddingBottom: "20px",
                    paddingTop: "20px",
                  }}
                >
                  <a
                    href="mailto:comunicacio@mercantic.com"
                    style={{ color: "var(--mercapink)" }}
                  >
                    {data.footer.footerWork}
                  </a>
                </div> */}
              </div>
              <div className="contactPage__content__right">
                <img
                  src={mapa}
                  style={{ height: "65vh", width: "100%", objectFit: "cover" }}
                  alt=""
                />
                {data.contacte !== undefined && (
                  <div
                    style={{
                      color: "var(--mercared)",
                      fontSize: "14px",
                      marginTop: "0px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="contactPage__content__right__first">
                      <p>{data.contacte.howToGet.first.first}</p>
                      <p>{data.contacte.howToGet.first.second}</p>
                      <p>{data.contacte.howToGet.first.third}</p>

                      <p>{data.contacte.howToGet.first.fourth}</p>
                    </div>
                    <div className="contactPage__content__right__second">
                      <p>{data.contacte.howToGet.second.first}</p>
                      <p>{data.contacte.howToGet.second.second}</p>
                      <p>{data.contacte.howToGet.second.third}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="contactPage__footer">
            <div className="contactPage__languages">
              {data.dropdown !== undefined &&
                data.dropdown.languages.map((item) => {
                  return (
                    <p
                      key={item.key}
                      style={{ color: "var(mercapink)" }}
                      onClick={() => {
                        handlelanguage(item.key);
                      }}
                    >
                      {item.text}
                    </p>
                  );
                })}
            </div>
            {data.dropdown !== undefined && (
              <div className="footer-laptop-wrapper">
                <p
                  onClick={() => {
                    navigate("/event");
                  }}
                >
                  {data.dropdown.footer.footer1}
                </p>
                <p
                  onClick={() => {
                    navigate("/company");
                  }}
                >
                  {data.dropdown.footer.footer2}
                </p>
              </div>
            )}
          </div>
        </div>
        <div style={{ width: "100%", backgroundColor: "var(--mercapink)" }}>
          <div className="contactPage__newsletter-container">
            <Newsletter />
          </div>
        </div>
        <div style={{ height: "100%", display: "flex" }}>
          <Footer
            color={"var(--mercapink)"}
            backgroundColor={"var(--mercared)"}
            logo={logo}
          />
        </div>
      </div>
    );
  }
};

export default ContactPage;

//REACT
import React from "react";
import { useContext, useEffect, useState } from "react";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { UserActivity } from "../../../context/UserActivity";
import { NavbarActivity } from "../../../context/NavbarActivity";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
import { HashLink } from "react-router-hash-link";
import { useScroll } from "../../../custom-hooks/useScroll";

//STYLES
import "./GastronomiaPage.css";
import {
  LinkContainer,
  Link,
  MobileLinkContainer,
  MobileLink,
} from "./GastronomiaStyles";
//IMAGES
import footerLogo from "../../../assets/Footer/logoGast.svg";
//COMPONENTS
import InitialPage from "../../Individual/InitialPage/InitialPage";
import GastronomiaDefaultPage from "../../Individual/DefaultPages/GastronomiaDefaultPage/GastronomiaDefaultPage";
import Footer from "../../Individual/Footer/Footer";
import MobileSectionMenu from "../../Individual/MobileSectionMenu/MobileSectionMenu";
import DefaultCarousel from "../../Individual/DefaultPages/DefaultCarousel/DefaultCarousel";
import {
  canGulaImages,
  casaÑidroImages,
  cocinaInquietaImages,
  gretaImages,
  tavernaImages,
} from "../../../data/gastronomiaImages";
import { IKImage } from "imagekitio-react";

const GastronomiaPage = () => {
  const { data } = useContext(LanguageActivity);

  const displayMobile = useWindowSize();
  const { setNavbarColor, setHamburgerColor } = useContext(NavbarActivity);
  const {
    mobileSectionMenuSelected,
    setMobileSectionMenuSelected,
    dropdownMenuSelected,
    setEnableScrollToTop,
  } = useContext(UserActivity);
  const [scrollToTopController, setScrollToTopController] = useState(true);
  useEffect(() => {
    if (scrollToTopController === false) {
      setEnableScrollToTop(false);
      setScrollToTopController(false);
    }
    //eslint-disable-next-line
  }, [scrollToTopController]);
  const { scrollPercent } = useScroll();
  const handleOpacity = (index) => {
    if (index === 0) {
      if (scrollPercent <= 150) {
        return 1;
      } else return 0.5;
    }
    if (index === 1) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 150 && scrollPercent < 250) {
        return 1;
      } else return 0.5;
    }
    if (index === 2) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 250 && scrollPercent < 350) {
        return 1;
      } else return 0.5;
    }
    if (index === 3) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 350 && scrollPercent < 450) {
        return 1;
      } else return 0.5;
    }
    if (index === 4) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 450 && scrollPercent < 550) {
        return 1;
      } else return 0.5;
    }
    if (index === 5) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 550) {
        return 1;
      } else return 0.5;
    }
  };
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--pagination-color",
      "var(--mercapink)"
    );
    document.documentElement.style.setProperty("--swiper-wrapper-padding", "0");
    if (!displayMobile) {
      setNavbarColor("var(--mercapink)");
      setHamburgerColor("var(--mercapink)");
    } else if (displayMobile) {
      setNavbarColor("var(--mercabrown)");
      setHamburgerColor("var(--mercabrown)");
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (displayMobile) {
      if (!dropdownMenuSelected) {
        if (scrollPercent > 103) {
          setNavbarColor("var(--mercapink)");
          setHamburgerColor("var(--mercapink)");
        } else {
          setNavbarColor("var(--mercabrown)");
          setHamburgerColor("var(--mercabrown)");
        }
      }
    } else if (!displayMobile) {
      if (scrollPercent < 28) {
        setNavbarColor("var(--mercapink)");
        setHamburgerColor("var(--mercapink)");
      } else if (scrollPercent > 28) {
        setHamburgerColor("var(--mercabrown)");
        setNavbarColor("var(--mercapink)");
      }
    }
    //eslint-disable-next-line
  }, [scrollPercent, dropdownMenuSelected]);
  if (displayMobile) {
    return (
      <>
        {data.gastronomia !== undefined && (
          <>
            <InitialPage
              section={data.gastronomia.initialPage.section}
              text={data.gastronomia.initialPage.text}
              link={data.gastronomia.initialPage.link}
              backgroundColor={" #F298C0"}
              color={"#9B5319"}
              image1={
                "https://ik.imagekit.io/gwms6thri/InitialPage/initialPageGastronomia.jpg?updatedAt=1690295680283"
              }
              image2={
                "https://ik.imagekit.io/gwms6thri/InitialPage/GASTRONOMIA.svg?updatedAt=1690294497122"
              }
            />
            <div style={{ position: "sticky", zIndex: 200, opacity: 0.99 }}>
              <GastronomiaDefaultPage
                title={data.gastronomia.frame2.title}
                text={data.gastronomia.frame2.text}
                id={data.gastronomia.initialPage.laptop[0]}
                phone={"644 69 30 97"}
              />
            </div>

            <DefaultCarousel images={casaÑidroImages} />
            <div style={{ position: "sticky", zIndex: 200, opacity: 0.99 }}>
              <GastronomiaDefaultPage
                title={data.gastronomia.frame4.title}
                text={data.gastronomia.frame4.text}
                id={data.gastronomia.initialPage.laptop[1]}
                phone={"934 88 43 85"}
              />
            </div>
            <DefaultCarousel images={canGulaImages} />
            <div style={{ position: "sticky", zIndex: 200, opacity: 0.99 }}>
              <GastronomiaDefaultPage
                title={data.gastronomia.frame6.title}
                text={data.gastronomia.frame6.text}
                id={data.gastronomia.initialPage.laptop[2]}
                phone={"679 46 95 50"}
              />
            </div>
            <DefaultCarousel images={tavernaImages} />
            <div style={{ position: "sticky", zIndex: 200, opacity: 0.99 }}>
              <GastronomiaDefaultPage
                title={data.gastronomia.frame8.title}
                text={data.gastronomia.frame8.text}
                id={data.gastronomia.initialPage.laptop[3]}
                phone={"673805115"}
              />
            </div>
            <DefaultCarousel images={gretaImages} />
            <div style={{ position: "sticky", zIndex: 200, opacity: 0.99 }}>
              <GastronomiaDefaultPage
                title={data.gastronomia.frame10.title}
                text={data.gastronomia.frame10.text}
                id={data.gastronomia.initialPage.laptop[4]}
              />
            </div>
            <DefaultCarousel images={cocinaInquietaImages} />

            {mobileSectionMenuSelected && data.gastronomia !== undefined && (
              <MobileSectionMenu
                items={data.gastronomia.initialPage.laptop}
                color={"var(--mercabrown)"}
                backgroundColor={"var(--mercapink)"}
                section="gastronomy"
              />
            )}
            <Footer
              backgroundColor={"var(--mercapink)"}
              color={"var(--mercabrown)"}
              logo={footerLogo}
            />
          </>
        )}
        {data.espais !== undefined && mobileSectionMenuSelected === false && (
          <MobileLinkContainer backgroundColor={"var(--mercapink)"}>
            <MobileLink
              color={"var(--mercabrown)"}
              onClick={() => {
                setMobileSectionMenuSelected(true);
              }}
            >
              {data.espais.initialPage.link}
            </MobileLink>
          </MobileLinkContainer>
        )}
      </>
    );
  } else if (!displayMobile) {
    return (
      <div className="gastronomiaPage-container">
        {data.gastronomia !== undefined && (
          <>
            <div className="laptop-top-wrapper">
              <InitialPage
                section={data.gastronomia.initialPage.section}
                text={data.gastronomia.initialPage.text}
                link={data.gastronomia.initialPage.link}
                backgroundColor={"#F298C0"}
                color={"#9B5319"}
                map={data.gastronomia.initialPage.laptop}
                page={data.gastronomia.initialPage.page}
                firstSquareWidth={"60%"}
                secondSquareWidth={"75%"}
                firstSquareHeight={16}
                secondSquareHeight={16}
                gastronomy={true}
              />
              <div style={{ width: "50%" }}>
                <IKImage
                  src={
                    "https:ik.imagekit.io/gwms6thri/InitialPage/initialPageGastronomia.jpg?updatedAt=1690295680283"
                  }
                  lqip={{ active: true, quality: 20, blur: 10 }}
                  alt="initial-laptop"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <div className="laptop-defaultPage-wrapper">
                  <GastronomiaDefaultPage
                    title={data.gastronomia.frame2.title}
                    text={data.gastronomia.frame2.text}
                    id={data.gastronomia.initialPage.laptop[0]}
                    phone={"644 69 30 97"}
                  />
                  <GastronomiaDefaultPage
                    title={data.gastronomia.frame4.title}
                    text={data.gastronomia.frame4.text}
                    id={data.gastronomia.initialPage.laptop[1]}
                    phone={"934 88 43 85"}
                  />
                  <GastronomiaDefaultPage
                    title={data.gastronomia.frame6.title}
                    text={data.gastronomia.frame6.text}
                    id={data.gastronomia.initialPage.laptop[2]}
                    phone={"679 46 95 50"}
                  />
                  <GastronomiaDefaultPage
                    title={data.gastronomia.frame8.title}
                    text={data.gastronomia.frame8.text}
                    id={data.gastronomia.initialPage.laptop[3]}
                    phone={"673805115"}
                  />
                  <GastronomiaDefaultPage
                    title={data.gastronomia.frame10.title}
                    text={data.gastronomia.frame10.text}
                    id={data.gastronomia.initialPage.laptop[4]}
                  />
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <DefaultCarousel images={casaÑidroImages} />
                <DefaultCarousel images={canGulaImages} />
                <DefaultCarousel images={tavernaImages} />
                <DefaultCarousel images={gretaImages} />
                <DefaultCarousel images={cocinaInquietaImages} />
              </div>
            </div>
          </>
        )}
        <div style={{ height: "100%", display: "flex" }}>
          <Footer
            color={"var(--mercabrown)"}
            backgroundColor={"var(--mercapink)"}
            logo={footerLogo}
          />
        </div>
        {data.gastronomia !== undefined && (
          <LinkContainer backgroundColor={"var(--mercapink)"}>
            {data.gastronomia.initialPage.laptop.map((item, index) => {
              return (
                <HashLink
                  to={`/gastronomy#${item.idName}`}
                  scroll={(el) =>
                    el.scrollIntoView({ behavior: "smooth", block: "end" })
                  }
                  key={index}
                  onClick={() => {
                    setEnableScrollToTop(false);
                    setScrollToTopController(false);
                  }}
                >
                  <Link
                    color={"var(--mercabrown)"}
                    opacity={handleOpacity(index)}
                  >
                    {item.name}
                  </Link>
                </HashLink>
              );
            })}
          </LinkContainer>
        )}
      </div>
    );
  }
};

export default GastronomiaPage;

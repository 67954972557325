//REACT
import React, { useState, useEffect, useContext } from "react";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
//STYLES
import "./AgendaColumn.css";

import AgendaCard from "../AgendaCard/AgendaCard";
import { LanguageActivity } from "../../../context/LanguageActivity";

const AgendaColumn = ({ column, display, number, holidays }) => {
  const [sortedColumn, setSortedColumn] = useState([]);
  const { languageSelected } = useContext(LanguageActivity);
  const displayMobile = useWindowSize();

  useEffect(() => {
    const convertTimeToMinutes = (timeString) => {
      if (!timeString || typeof timeString !== "string") {
        console.error(`Invalid timeString: '${timeString}'`);
        return undefined;
      }

      try {
        const startTime = timeString.split(" - ")[0].trim(); // Hora inicial
        let [hours, minutes = 0] = startTime
          .replace("h", ":")
          .split(":")
          .map(Number);

        if (isNaN(hours) || isNaN(minutes)) {
          throw new Error(`Invalid time format in: '${startTime}'`);
        }

        return hours * 60 + minutes;
      } catch (error) {
        console.error(`Error processing timeString: '${timeString}'`, error);
        return undefined;
      }
    };

    if (column) {
      const sorted = [...column].sort((a, b) => {
        const startTimeA = convertTimeToMinutes(a?.time);
        const startTimeB = convertTimeToMinutes(b?.time);

        if (startTimeA === undefined || startTimeB === undefined) {
          console.warn(
            `Invalid times for comparison: '${a?.time}' vs '${b?.time}'`
          );
          return 0; // Manté l'ordre original si hi ha valors invàlids
        }

        return startTimeA - startTimeB;
      });

      setSortedColumn(sorted); // Actualitza l'estat
    }
  }, [column, languageSelected]); // Dependència en column i languageSelected

  if (!displayMobile) {
    return (
      <div className="agenda-column__container">
        <div className="agenda-column__day__wrapper">
          {number !== undefined && (
            <p className="agenda-column__day__number">{number}</p>
          )}
        </div>
        <div className="agenda-column__wrapper">
          {display &&
            column !== undefined &&
            sortedColumn.map((item, index) => {
              return (
                <AgendaCard
                  section={item.section}
                  subsection={item.subsection}
                  time={item.time}
                  book={item.book}
                  info={item.info}
                  type={item.type}
                  filter={item.filter}
                  filter2={item.filter2}
                  key={index}
                  route={item.route}
                  holidays={holidays}
                  bookAddressMail={item.bookAddressMail}
                  bookAddressRoute={item.bookAddressRoute}
                  externalBookAddress={item.externalBookAddress}
                  externalInfo={item.externalInfo}
                  sell={item.sell}
                />
              );
            })}
          {display && holidays !== undefined && (
            <AgendaCard holidays={holidays} />
          )}
        </div>
      </div>
    );
  } else if (displayMobile) {
    return null;
  }
};

export default AgendaColumn;

import React, { useContext } from "react";
import { LanguageActivity } from "../../../../context/LanguageActivity";
//STYLES
import "./GastronomiaDefaultPage.css";

const GastronomiaDefaultPage = ({ title, text, id, phone }) => {
  const { languageSelected } = useContext(LanguageActivity);
  if (id !== undefined) {
    return (
      <div className="gastronomiaPage-frame2" id={id.idName}>
        <div className="gastronomiaPage-frame2-top-wrapper">
          <h3>
            {title.br1}
            <br />
            {title.br2 && title.br2}
          </h3>
          <p>{text}</p>
          {phone &&
            (languageSelected?.catala === true ||
            languageSelected?.castella === true ? (
              <p>Telf: {phone}</p>
            ) : (
              <p>Phone: {phone}</p>
            ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default GastronomiaDefaultPage;

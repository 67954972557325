//REACT
import React, { useContext, useEffect, useState } from "react";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { UserActivity } from "../../../context/UserActivity";
import { NavbarActivity } from "../../../context/NavbarActivity";
import { agendaData } from "../../../data/AgendaData";
import AgendaColumn from "../../Individual/AgendaColumn/AgendaColumn";
//STYLES
import "./AgendaPage.css";

//COMPONENTS
import AgendaCard from "../../Individual/AgendaCard/AgendaCard";
import AgendaFilter from "../../Individual/AgendaFilter/AgendaFilter";

const AgendaPage = () => {
  const displayMobile = useWindowSize();
  const [sortedColumn1, setSortedColumn1] = useState([]);
  const [sortedColumn2, setSortedColumn2] = useState([]);
  const [sortedColumn3, setSortedColumn3] = useState([]);
  const [sortedColumn4, setSortedColumn4] = useState([]);
  const [sortedColumn5, setSortedColumn5] = useState([]);
  const [currentDate, setCurrentDate] = useState(null);
  const { data, dataIsLoaded } = useContext(LanguageActivity);
  const { setNavbarColor, setHamburgerColor, setNavbarBackground } =
    useContext(NavbarActivity);
  const { dropdownMenuSelected } = useContext(UserActivity);

  useEffect(() => {
    setNavbarColor("var(--skyblue)");
    setHamburgerColor("var(--skyblue)");
    setNavbarBackground("var(--mercagreen)");

    const current = new Date();
    const date = current.getDate();
    setCurrentDate(date);
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    const convertTimeToMinutes = (timeString) => {
      if (!timeString || typeof timeString !== "string") {
        console.error(`Invalid timeString: '${timeString}'`);
        return undefined;
      }

      try {
        const startTime = timeString.split(" - ")[0].trim(); // Hora inicial
        let [hours, minutes = 0] = startTime
          .replace("h", ":")
          .split(":")
          .map(Number);

        if (isNaN(hours) || isNaN(minutes)) {
          throw new Error(`Invalid time format in: '${startTime}'`);
        }

        return hours * 60 + minutes; // Conversió a minuts
      } catch (error) {
        console.error(`Error processing timeString: '${timeString}'`, error);
        return undefined;
      }
    };

    if (dataIsLoaded && data?.agenda?.[1]) {
      const {
        1: element1,
        2: element2,
        3: element3,
        4: element4,
        5: element5,
      } = data.agenda;

      // Iterem sobre cada item de element1
      const sortedElements2 = element2.map((item, index) => {
        if (item.activities && Array.isArray(item.activities)) {
          const sortedActivities = [...item.activities].sort((a, b) => {
            const startTimeA = convertTimeToMinutes(a.time);
            const startTimeB = convertTimeToMinutes(b.time);

            if (startTimeA === undefined || startTimeB === undefined) {
              console.warn(
                `Invalid times in element ${index}: '${a.time}' vs '${b.time}'`
              );
              return 0; // Manté l'ordre original si hi ha valors invàlids
            }

            return startTimeA - startTimeB;
          });
          return { ...item, activities: sortedActivities }; // Retornem l'element amb activitats ordenades
        } else {
          console.warn(
            `No activities found or not an array in element ${index}`
          );
          return item; // Retorna l'element original si no té activitats vàlides
        }
      });
      const sortedElements3 = element3.map((item, index) => {
        if (item.activities && Array.isArray(item.activities)) {
          const sortedActivities = [...item.activities].sort((a, b) => {
            const startTimeA = convertTimeToMinutes(a.time);
            const startTimeB = convertTimeToMinutes(b.time);

            if (startTimeA === undefined || startTimeB === undefined) {
              console.warn(
                `Invalid times in element ${index}: '${a.time}' vs '${b.time}'`
              );
              return 0; // Manté l'ordre original si hi ha valors invàlids
            }

            return startTimeA - startTimeB;
          });
          return { ...item, activities: sortedActivities }; // Retornem l'element amb activitats ordenades
        } else {
          console.warn(
            `No activities found or not an array in element ${index}`
          );
          return item; // Retorna l'element original si no té activitats vàlides
        }
      });
      const sortedElements4 = element4.map((item, index) => {
        if (item.activities && Array.isArray(item.activities)) {
          const sortedActivities = [...item.activities].sort((a, b) => {
            const startTimeA = convertTimeToMinutes(a.time);
            const startTimeB = convertTimeToMinutes(b.time);

            if (startTimeA === undefined || startTimeB === undefined) {
              console.warn(
                `Invalid times in element ${index}: '${a.time}' vs '${b.time}'`
              );
              return 0; // Manté l'ordre original si hi ha valors invàlids
            }

            return startTimeA - startTimeB;
          });
          return { ...item, activities: sortedActivities }; // Retornem l'element amb activitats ordenades
        } else {
          console.warn(
            `No activities found or not an array in element ${index}`
          );
          return item; // Retorna l'element original si no té activitats vàlides
        }
      });
      const sortedElements5 = element5.map((item, index) => {
        if (item.activities && Array.isArray(item.activities)) {
          const sortedActivities = [...item.activities].sort((a, b) => {
            const startTimeA = convertTimeToMinutes(a.time);
            const startTimeB = convertTimeToMinutes(b.time);

            if (startTimeA === undefined || startTimeB === undefined) {
              console.warn(
                `Invalid times in element ${index}: '${a.time}' vs '${b.time}'`
              );
              return 0; // Manté l'ordre original si hi ha valors invàlids
            }

            return startTimeA - startTimeB;
          });
          return { ...item, activities: sortedActivities }; // Retornem l'element amb activitats ordenades
        } else {
          console.warn(
            `No activities found or not an array in element ${index}`
          );
          return item; // Retorna l'element original si no té activitats vàlides
        }
      });
      const sortedElements = element1.map((item, index) => {
        if (item.activities && Array.isArray(item.activities)) {
          const sortedActivities = [...item.activities].sort((a, b) => {
            const startTimeA = convertTimeToMinutes(a.time);
            const startTimeB = convertTimeToMinutes(b.time);

            if (startTimeA === undefined || startTimeB === undefined) {
              console.warn(
                `Invalid times in element ${index}: '${a.time}' vs '${b.time}'`
              );
              return 0; // Manté l'ordre original si hi ha valors invàlids
            }

            return startTimeA - startTimeB;
          });
          return { ...item, activities: sortedActivities }; // Retornem l'element amb activitats ordenades
        } else {
          console.warn(
            `No activities found or not an array in element ${index}`
          );
          return item; // Retorna l'element original si no té activitats vàlides
        }
      });

      setSortedColumn1(sortedElements);
      setSortedColumn2(sortedElements2);
      setSortedColumn3(sortedElements3);
      setSortedColumn4(sortedElements4);
      setSortedColumn5(sortedElements5);
    }
  }, [dataIsLoaded, data]);

  if (displayMobile) {
    return (
      <>
        <div
          className={dropdownMenuSelected ? "overflow" : "agenda__container"}
        >
          <div className="agenda-mobile-fixingDiv"></div>
          {data.agenda !== undefined && (
            <div className="agenda-mobile__month-container">
              <div className="agenda-mobile__month-slide">
                <div className="agenda-mobile__month-wrapper">
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                </div>
                <div className="agenda-mobile__month-wrapper">
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                </div>
              </div>
            </div>
          )}

          {data.agenda !== undefined &&
            //eslint-disable-next-line
            sortedColumn1.map((item, index) => {
              if (item.display) {
                return (
                  <AgendaCard
                    display={item.display}
                    day={item.dia}
                    number={item.number}
                    activities={item.activities}
                    key={index}
                    date={currentDate}
                    holidays={item.holidays}
                  />
                );
              } else if (item.display === false) {
                return null;
              }
            })}
          {data.agenda !== undefined &&
            //eslint-disable-next-line
            sortedColumn2.map((item, index) => {
              if (item.display) {
                return (
                  <AgendaCard
                    display={item.display}
                    day={item.dia}
                    number={item.number}
                    activities={item.activities}
                    key={index}
                    date={currentDate}
                    holidays={item.holidays}
                  />
                );
              } else if (item.display === false) {
                return null;
              }
            })}
          {data.agenda !== undefined &&
            //eslint-disable-next-line
            sortedColumn3.map((item, index) => {
              if (item.display) {
                return (
                  <AgendaCard
                    display={item.display}
                    day={item.dia}
                    number={item.number}
                    activities={item.activities}
                    key={index}
                    date={currentDate}
                    holidays={item.holidays}
                  />
                );
              } else if (item.display === false) {
                return null;
              }
            })}

          {data.agenda !== undefined &&
            //eslint-disable-next-line
            sortedColumn4.map((item, index) => {
              if (item.display) {
                return (
                  <AgendaCard
                    display={item.display}
                    day={item.dia}
                    number={item.number}
                    activities={item.activities}
                    key={index}
                    date={currentDate}
                    holidays={item.holidays}
                  />
                );
              } else if (item.display === false) {
                return null;
              }
            })}

          {data.agenda !== undefined &&
            //eslint-disable-next-line
            sortedColumn5.map((item, index) => {
              if (item.display) {
                return (
                  <AgendaCard
                    display={item.display}
                    day={item.dia}
                    number={item.number}
                    activities={item.activities}
                    key={index}
                    date={`${currentDate}/07`}
                    holidays={item.holidays}
                  />
                );
              } else if (item.display === false) {
                return null;
              }
            })}

          {data.agenda !== undefined && (
            <AgendaFilter
              info={agendaData.catala.filterMenu.slice(1)}
              month={data.agenda.month}
            />
          )}
        </div>
      </>
    );
  } else if (!displayMobile) {
    return (
      <>
        <div className="agenda__day__column">
          {data.agenda !== undefined &&
            data.agenda[1].map((item, index) => {
              return (
                <div className="agenda__day__column__wrapper" key={index}>
                  <p>{item.dia}</p>
                </div>
              );
            })}
        </div>
        <div
          className="agenda__container"
          onClick={() => {
            console.log(data.agenda[1]);
          }}
        >
          {data.agenda !== undefined && (
            <>
              <div className="agenda__row__container">
                {data.agenda[1].map((item, index) => {
                  return (
                    <AgendaColumn
                      column={item.activities}
                      display={item.display}
                      day={item.day}
                      number={item.number}
                      key={index}
                    />
                  );
                })}
              </div>
              <div className="agenda__day__column">
                {data.agenda !== undefined &&
                  data.agenda[1].map((item, index) => {
                    return (
                      <div className="agenda__day__column__wrapper" key={index}>
                        <p>{item.dia}</p>
                      </div>
                    );
                  })}
              </div>
              <div className="agenda__row__container">
                {data.agenda[2].map((item, index) => {
                  return (
                    <AgendaColumn
                      column={item.activities}
                      display={item.display}
                      day={item.day}
                      number={item.number}
                      key={index}
                    />
                  );
                })}
              </div>
              <div className="agenda__day__column">
                {data.agenda !== undefined &&
                  data.agenda[1].map((item, index) => {
                    return (
                      <div className="agenda__day__column__wrapper" key={index}>
                        <p>{item.dia}</p>
                      </div>
                    );
                  })}
              </div>
              <div className="agenda__row__container">
                {data.agenda[3].map((item, index) => {
                  return (
                    <AgendaColumn
                      column={item.activities}
                      display={item.display}
                      day={item.day}
                      number={item.number}
                      key={index}
                    />
                  );
                })}
              </div>
              <div className="agenda__day__column">
                {data.agenda !== undefined &&
                  data.agenda[1].map((item, index) => {
                    return (
                      <div className="agenda__day__column__wrapper" key={index}>
                        <p>{item.dia}</p>
                      </div>
                    );
                  })}
              </div>
              <div className="agenda__row__container">
                {data.agenda[4].map((item, index) => {
                  return (
                    <AgendaColumn
                      column={item.activities}
                      display={item.display}
                      day={item.day}
                      number={item.number}
                      key={index}
                    />
                  );
                })}
              </div>
              <div className="agenda__day__column">
                {data.agenda !== undefined &&
                  data.agenda[1].map((item, index) => {
                    return (
                      <div className="agenda__day__column__wrapper" key={index}>
                        <p>{item.dia}</p>
                      </div>
                    );
                  })}
              </div>
              <div className="agenda__row__container">
                {data.agenda[5].map((item, index) => {
                  return (
                    <AgendaColumn
                      column={item.activities}
                      display={item.display}
                      day={item.day}
                      number={item.number}
                      key={index}
                    />
                  );
                })}
              </div>
              <AgendaFilter
                info={data.agendaFilter}
                month={data.agenda.month}
              />
            </>
          )}
        </div>
      </>
    );
  }
};

export default AgendaPage;

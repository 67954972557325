//REACT
import React, { useEffect } from "react";
import { UserActivity } from "../../../context/UserActivity";
import { useContext, useState } from "react";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { NavbarActivity } from "../../../context/NavbarActivity";
import { useScroll } from "../../../custom-hooks/useScroll";
import { useNavigate } from "react-router-dom";
import { IKImage } from "imagekitio-react";
import CookiesBanner from "../../Individual/CookiesBanner/CookiesBanner";
import "animate.css";
//STYLES
import "./HomePage.css";
import {
  ItemWrapper,
  HomePageFrame5,
  HomePageFrame5Paragraph,
  //eslint-disable-next-line
  HomePageFrame6,
  HomePageFrame8,
  HomePageFrame2,
  HomePageFrame2Img,
  HomePageFrame2Div1,
  HomePageFrame2Div2,
  NavbarAnimationImg,
  ControllerBlock,
} from "./HomePageStyledComponents";

//IMAGES
import homePageFrame2 from "../../../assets/HomePage/homePage-frame2.jpg";
import homePageFrame3 from "../../../assets/HomePage/HomePage-frame5.jpg";
import homePageFrame5 from "../../../assets/HomePage/HomePage-frame5.jpg";
import footerLogo from "../../../assets/Footer/footer-logo.svg";
import mobileBackground from "../../../assets/HomePage/mobileBackground.jpg";
import dynamicLogoMobile from "../../../assets/HomePage/logoMobile.svg";
import logo from "../../../assets/HomePage/Logo_Home_Desktop.svg";
import flipMenu from "../../../assets/HomePage/homePage -flipMenu.jpg";
//COMPONENTS
import HomePageMenu from "../../Individual/HomePageMenu/HomePageMenu";
import HomePageCarusel from "../../Individual/HomePageCarousel/HomePageCarousel";
import HomePageCarousel2 from "../../Individual/HomePageCarousel2/HomePageCarousel2";
import MailchimpFormContainer from "../../Individual/Newsletter/MailChimp";
import Footer from "../../Individual/Footer/Footer";
import AnimatedSpan from "../../Individual/AnimatedSpan/AnimatedSpan";
// import Newsletter from "../../Individual/Newsletter/Newsletter";
// import Mailchimp from "../../Individual/Newsletter/MailChimp";
const HomePage = () => {
  const { data } = useContext(LanguageActivity);
  const [menuHoverController, setMenuHoverController] = useState(null);
  const [footerRoute, setFooterRoute] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const displayMobile = useWindowSize();
  const navigate = useNavigate();

  const {
    dynamicBackgroundColor,
    setNavbarColor,
    setDynamicBackgroundColor,
    setHamburgerColor,
    hamburgerColor,
  } = useContext(NavbarActivity);
  const { dropdownMenuSelected, displayCookiesBanner, setEnableScrollToTop } =
    useContext(UserActivity);
  const { scrollPercent } = useScroll();

  const handleMenuHover = (number) => {
    switch (number) {
      case "01":
        setMenuHoverController(0);
        setFooterRoute("/markets");
        break;
      case "02":
        setMenuHoverController(1);
        setFooterRoute("/schedule");
        break;
      case "03":
        setMenuHoverController(2);
        setFooterRoute("/workshops");
        break;
      case "04":
        setMenuHoverController(3);
        setFooterRoute("/gastronomy");
        break;
      case null:
        setMenuHoverController(null);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (menuHoverController === null) {
      if (scrollPercent === 0) {
        setHamburgerColor("var(--skyblue)");
      }
      setNavbarColor("var(--mercagreen)");
      setHamburgerColor("var(--mercagreen)");
      setDynamicBackgroundColor("var(--skyblue)");
    }

    if (menuHoverController !== null) {
      setHamburgerColor("var(--mercagreen)");
      setDynamicBackgroundColor("var(--mercagreen)");
    }
    if (
      scrollPercent > 350 &&
      scrollPercent < 458 &&
      menuHoverController !== null
    ) {
      setHamburgerColor("var(--skyblue)");
    }
    //eslint-disable-next-line
  }, [menuHoverController]);
  useEffect(() => {
    if ((scrollPercent < 50 && !dropdownMenuSelected) || scrollPercent === 0) {
      setNavbarColor("var(--mercagreen)");
      setHamburgerColor("var(--skyblue)");
    } else {
      setHamburgerColor("var(--mercagreen)");
    }
    setMenuHoverController(null);
    //eslint-disable-next-line
    if (scrollPercent > 223 && scrollPercent < 305 && displayMobile) {
      setNavbarColor("var(--skyblue)");
      setHamburgerColor("var(--skyblue)");
    } else if (
      (scrollPercent < 250 && displayMobile) ||
      (scrollPercent > 330 && displayMobile)
    ) {
      setNavbarColor("var(--mercagreen)");
      setHamburgerColor("var(--mercagreen)");
    }
    //eslint-disable-next-line
  }, [scrollPercent]);

  useEffect(() => {
    localStorage.setItem("hoverController", null);
    document.documentElement.style.setProperty(
      "--pagination-color",
      "var(--skyblue)"
    );
    console.log(
      "hamburgerColor in initial useEffect HomePage: ",
      hamburgerColor
    );
    //eslint-disable-next-line
  }, []);

  if (displayMobile) {
    return (
      <>
        {displayCookiesBanner && <CookiesBanner />}
        <NavbarAnimationImg
          src={dynamicLogoMobile}
          alt="navbarAnimation"
          width={100 - scrollPercent}
          opacity={scrollPercent <= 60 ? 1 - scrollPercent / 100 : "0"}
          position={"fixed"}
          top={displayCookiesBanner ? "100px" : 0}
        />
        <div className="homepage-frame1">
          <img src={mobileBackground} alt="paint" className="paint" />
        </div>
        <div className="homepage-frame2">
          {data.homePage !== undefined && (
            <p className="frame2-paragraph1">
              {data.homePage !== undefined && (
                <>
                  <AnimatedSpan>
                    {data.homePage.frame2.mobile.paragraph1.br1}
                  </AnimatedSpan>
                  <AnimatedSpan>
                    {data.homePage.frame2.mobile.paragraph1.br2}
                  </AnimatedSpan>
                  <AnimatedSpan>
                    {data.homePage.frame2.mobile.paragraph1.br3}
                  </AnimatedSpan>
                  <AnimatedSpan>
                    {data.homePage.frame2.mobile.paragraph1.br4}
                  </AnimatedSpan>
                  <AnimatedSpan>
                    {data.homePage.frame2.mobile.paragraph1.br5}
                  </AnimatedSpan>
                  <AnimatedSpan>
                    {data.homePage.frame2.mobile.paragraph1.br6}
                  </AnimatedSpan>
                </>
              )}
            </p>
          )}
          <img
            src={homePageFrame3}
            style={{ height: "300px" }}
            alt="sunnyday"
          />
          {data.homePage !== undefined && (
            <p className="frame2-paragraph2">
              <AnimatedSpan>
                {data.homePage.frame2.mobile.paragraph2.br1}
              </AnimatedSpan>
              <AnimatedSpan>
                {data.homePage.frame2.mobile.paragraph2.br2}
              </AnimatedSpan>
              <AnimatedSpan>
                {data.homePage.frame2.mobile.paragraph2.br3}
              </AnimatedSpan>
              <AnimatedSpan>
                {data.homePage.frame2.mobile.paragraph2.br4}
              </AnimatedSpan>
              <AnimatedSpan>
                {data.homePage.frame2.mobile.paragraph2.br5}
              </AnimatedSpan>
              <AnimatedSpan>
                {data.homePage.frame2.mobile.paragraph2.br6}
              </AnimatedSpan>
              <AnimatedSpan>
                {data.homePage.frame2.mobile.paragraph2.br7}
              </AnimatedSpan>
            </p>
          )}
        </div>

        <HomePageCarusel
          info={data.homePage !== undefined ? data.homePage.frame4 : undefined}
          color={"var(--skyblue)"}
          backgroundColor={"var(--mercagreen)"}
        />
        <div className="homepage-frame5">
          {data.homePage !== undefined && (
            <p>{data.homePage.frame5.mobile.paragraph1}</p>
          )}

          <img alt="man" src={homePageFrame2} />

          {data.homePage !== undefined && (
            <p>{data.homePage.frame5.mobile.paragraph2}</p>
          )}
        </div>
        <div className="homepage-frame6" style={{ height: "350px" }}>
          {data.homePage !== undefined && (
            <p className="homepage-frame6-paragraph">
              {data.homePage.frame5.paragraph3}
            </p>
          )}
          <HomePageCarousel2 />
        </div>
        <ControllerBlock></ControllerBlock>
        <div className="homepage-frame7">
          <HomePageMenu />
        </div>
        <div className="homepage-frame8">
          <MailchimpFormContainer />
        </div>
        <Footer
          backgroundColor={"var(--mercagreen)"}
          color={"var(--skyblue)"}
          logo={footerLogo}
        />
      </>
    );
  } else if (!displayMobile) {
    return (
      <>
        {displayCookiesBanner && <CookiesBanner />}
        {data.homePage !== undefined && (
          <>
            {isLoaded && (
              <NavbarAnimationImg
                src={logo}
                alt="navbarAnimation"
                width={100 - scrollPercent}
                height={100 - scrollPercent}
                opacity={scrollPercent <= 54 ? 1 - scrollPercent / 100 : "0"}
                position={scrollPercent > 55 ? "absolute" : "fixed"}
                top={displayCookiesBanner ? "50px" : 0}
              />
            )}
            <div className="homepage-frame1">
              <IKImage
                src="https://ik.imagekit.io/gwms6thri/HomePage/newImageBackground.jpg?updatedAt=1690904007089"
                alt="background"
                className="paint"
                id="homepage-background"
                onLoad={() => {
                  setIsLoaded(true);
                }}
                lqip={{ active: true, quality: 20, blur: 40 }}
              />
            </div>
            <HomePageFrame2>
              {data.homePage.frame2 !== undefined && (
                <HomePageFrame2Div1>
                  <AnimatedSpan>
                    {data.homePage.frame2.laptop.paragraph1.br1}
                  </AnimatedSpan>
                  <AnimatedSpan>
                    {data.homePage.frame2.laptop.paragraph1.br2}
                  </AnimatedSpan>
                  <br />
                  <AnimatedSpan>
                    {data.homePage.frame2.laptop.paragraph1.br3}
                  </AnimatedSpan>
                  <br />
                  <AnimatedSpan>
                    {data.homePage.frame2.laptop.paragraph1.br4}
                  </AnimatedSpan>
                  <br />
                  <AnimatedSpan>
                    {data.homePage.frame2.laptop.paragraph1.br5}
                  </AnimatedSpan>
                  <AnimatedSpan>
                    {data.homePage.frame2.laptop.paragraph1.br6}
                  </AnimatedSpan>
                </HomePageFrame2Div1>
              )}
              <HomePageFrame2Img src={homePageFrame5} alt="sunnyday" />
              {data.homePage.frame2 !== undefined && (
                <HomePageFrame2Div2>
                  <br />
                  <AnimatedSpan>
                    {data.homePage.frame2.laptop.paragraph2.br1}
                  </AnimatedSpan>
                  <br />
                  <AnimatedSpan>
                    {data.homePage.frame2.laptop.paragraph2.br2}
                  </AnimatedSpan>
                  <br />
                  <AnimatedSpan>
                    {data.homePage.frame2.laptop.paragraph2.br3}
                  </AnimatedSpan>
                  <br />
                  <AnimatedSpan>
                    {data.homePage.frame2.laptop.paragraph2.br4}
                  </AnimatedSpan>
                  <br />
                  <AnimatedSpan>
                    {data.homePage.frame2.laptop.paragraph2.br5}
                  </AnimatedSpan>
                  <br />
                  <AnimatedSpan>
                    {data.homePage.frame2.laptop.paragraph2.br6}
                  </AnimatedSpan>
                  <br />
                  {data.homePage.frame2.laptop.paragraph2.br7}
                </HomePageFrame2Div2>
              )}
            </HomePageFrame2>
            <div className="homepage-frame3">
              <div className="homepage-frame3-controller">
                <div
                  style={{ height: "calc(100% / 3)" }}
                  onMouseEnter={() => {
                    handleMenuHover(null);
                  }}
                ></div>
                <div className="homepage-frame3-wrapper">
                  {data.homePage.frame4 !== undefined &&
                    data.homePage.frame4.map((item) => {
                      return (
                        <ItemWrapper
                          className="
                          fff-mercantic-regular-aalt
                        
                          // fff-mercantic-regular-dlig
                   
          
                    
                         "
                          key={item.key}
                          opacity={
                            item.key === menuHoverController ||
                            menuHoverController === null
                              ? "1"
                              : "0.5"
                          }
                          onMouseEnter={() => {
                            handleMenuHover(item.number);
                          }}
                          onClick={() => {
                            setEnableScrollToTop(true);
                            navigate(item.path);
                          }}
                        >
                          {item.number === "03" && (
                            <span className="homePageMenu-item-number-right">
                              {item.number}
                            </span>
                          )}
                          <span style={{ textTransform: "uppercase" }}>
                            {item.title}
                          </span>
                          {item.number !== "03" && (
                            <span className="homePageMenu-item-number">
                              {item.number}
                            </span>
                          )}
                        </ItemWrapper>
                      );
                    })}
                </div>
                <div
                  style={{ height: "calc(100% / 3)" }}
                  onMouseEnter={() => {
                    handleMenuHover(null);
                    setNavbarColor("var(--mercagreen)");
                    setHamburgerColor("var(--mercagreen)");
                    setDynamicBackgroundColor("var(--skyblue)");
                  }}
                ></div>
              </div>
              <div className="homepage-frame3-flip-container">
                {menuHoverController !== null &&
                data.homePage.frame4 !== undefined ? (
                  <div className="homepage-frame3-flip">
                    <p>
                      {data.homePage.frame4[menuHoverController].paragraph1}
                      {data.homePage.frame4[menuHoverController].paragraph2}
                    </p>
                    <div className="homepage-frame3-flip-footer">
                      <p onClick={() => navigate(`${footerRoute}`)}>
                        {
                          data.homePage.frame4[menuHoverController].footer
                            .footer1
                        }
                      </p>
                      <p
                        onClick={() => {
                          navigate("/schedule");
                        }}
                      >
                        {
                          data.homePage.frame4[menuHoverController].footer
                            .footer2
                        }
                      </p>
                    </div>
                  </div>
                ) : (
                  <img src={flipMenu} alt="flip" />
                )}
              </div>
            </div>
            <HomePageFrame5>
              {data.homePage.frame5 !== undefined && (
                <HomePageFrame5Paragraph>
                  {data.homePage.frame5.laptop.paragraph1}
                </HomePageFrame5Paragraph>
              )}
              <div className="homepage-frame5-img-wrapper">
                <img alt="man" src={homePageFrame2} />

                {data.homePage.frame5 !== undefined && (
                  <HomePageFrame5Paragraph>
                    {data.homePage.frame5.laptop.paragraph2}
                  </HomePageFrame5Paragraph>
                )}
              </div>
            </HomePageFrame5>
            <HomePageFrame6 dynamicBackground={dynamicBackgroundColor}>
              <p className="homepage-frame6-paragraph">
                {data.homePage.frame5.paragraph3}
              </p>
              <HomePageCarousel2 background={dynamicBackgroundColor} />
            </HomePageFrame6>
            <ControllerBlock
              dynamicBackground={dynamicBackgroundColor}
            ></ControllerBlock>
            <HomePageMenu />
            <HomePageFrame8 dynamicBackground={dynamicBackgroundColor}>
              <MailchimpFormContainer />
            </HomePageFrame8>
            <Footer
              backgroundColor={"var(--mercagreen)"}
              color={"var(--skyblue)"}
              logo={footerLogo}
            />
          </>
        )}
      </>
    );
  }
};

export default HomePage;
